<template>
  <div class="fanyi-box">
    <p class="obj-title">
      <span>变更风险评估</span>
      <span>
        <el-button type="primary" plain @click="toLishi()">历史数据</el-button>
        <el-button type="primary" plain @click="toNeirong()"
          >变更风险库</el-button
        >
      </span>
    </p>

    <div class="content-box">
      <div class="left-box">
        <div class="top-box">
          <div class="top-title">
            <span class="top-title-text">输入区域</span>
            <el-radio-group
              v-model="radio1"
              size="small"
              @click.native="modelChange"
            >
              <el-radio label="1" disabled>基础模式</el-radio>
              <el-radio label="2" disabled>增强模式</el-radio>
              <el-radio label="3" disabled>专业模式</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="input-box">
          <div class="f-c-b">
            <div class="input-box-item-50">
              <p class="input-title">变更类型</p>
              <el-input
                placeholder="在这里输入变更类型"
                v-model="prompt.changeType"
                style="width: 100%; line-height: 30px"
              >
              </el-input>
            </div>
            <div class="input-box-item-50">
              <p class="input-title">变更范围</p>
              <el-input
                placeholder="在这里输入变更范围"
                v-model="prompt.changeRange"
                style="width: 100%; line-height: 30px"
                class="no-resize"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="input-box">
          <p class="input-title">变更前</p>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="在这里输入变更前"
            v-model="prompt.changePre"
            style="width: 100%"
          >
          </el-input>
        </div>
        <div class="input-box">
          <p class="input-title">变更后</p>

          <el-input
            type="prompt.changeTarget"
            :rows="3"
            placeholder="在这里输入变更后"
            v-model="prompt.changeTarget"
            style="width: 100%"
          >
          </el-input>
        </div>
        <div class="input-box">
          <p class="input-title">变更原因</p>

          <el-input
            type="textarea"
            :rows="3"
            placeholder="在这里输入变更原因"
            v-model="prompt.changeReason"
            style="width: 100%"
            class="no-resize"
          >
          </el-input>
        </div>
        <div class="fanyi-btn">
          <!-- <el-button>取消</el-button> -->
          <el-button
            type="primary"
            @click="submitForm"
            id="submitButton"
            :disabled="submitButtonDisabled"
            >执行</el-button
          >
        </div>
      </div>
      <div
        class="right-box"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <div class="top-box">
          <div class="top-title">结果展示（内容由Gxpcode提供的AI服务生成）</div>
          <el-button @click="copyTranslation" :disabled="copyButtonDisabled"
            >复制</el-button
          >
        </div>
        <!-- <el-input
          readonly
          type="textarea"
          id="explanationArea"
          :rows="16"
          placeholder="这里会出现解释结果，请等候"
          v-model="explanation"
          class="jieguo-input no-resize"
        >
        </el-input> -->
        <div class="jieguo-input" id="explanationArea" ref="messageContainer">
          <vue-markdown :source="explanation"></vue-markdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "",

  components: { VueMarkdown },

  data() {
    return {
      homeThree: [{}, {}],
      radio1: "1",

      isMobile: false,
      prompt: {
        changeType: null,
        changeRange: null,
        changeReason: null,
        changeTarget: null,
        changePre: null,
        workPrinciples: [""], // 用于存储工作原理的数组
        processPrinciples: [""], // 用于存储工艺原理的数组
      },
      divStyle: {
        overflow: "hidden",
      },
      explanation: "",
      submitButtonColor: "#1a73e8",
      showSpinner: false,
      copyButtonDisabled: true,
      submitButtonDisabled: false,

      loading: false,
    };
  },

  created() {},

  mounted() {},

  methods: {
    modelChange() {
      this.$message({
        message: "当前仅向特定用户开放",
        type: "warning",
      });
    },
    submitForm() {
      if (
        this.prompt.changeType == null ||
        this.prompt.changeRange == null ||
        this.prompt.changeReason == null ||
        this.prompt.changeTarget == null ||
        this.prompt.changePre == null
      ) {
        // alert("参数不能为空");
        this.$message.error("参数不能为空");
        return;
      }

      console.log(JSON.stringify(this.prompt, null, 2));
      this.streamingData = [];
      if (this.prompt.changeType !== "") {
        this.scrollToBottom();
        let start = true;
        this.copyButtonDisabled = true;
        this.submitButtonDisabled = true;

        this.loading = true;

        fetch("https://api.gxpcode.com/openai/promptChangeAiFlow", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Gxpcode-Access-Token": localStorage.getItem("token"),
          },
          body: JSON.stringify(this.prompt),
        })
          .then((response) => response.body)
          .then((stream) => {
            this.loading = false;

            const reader = stream.getReader();
            const processStream = () => {
              reader.read().then(({ done, value }) => {
                if (done) {
                  return;
                }
                const bytes = new Uint8Array(value);
                const decoder = new TextDecoder();
                const str = decoder.decode(bytes);
                if (str == "-7") {
                  location.href = "/Login";
                }

                this.streamingData.push(str);
                this.strData = this.streamingData.join("");
                if (start) {
                  this.explanation = this.strData;
                  start = false;
                } else {
                  this.strData = this.strData.replace(
                    "根据提供的背景信息，",
                    ""
                  );
                  this.strData = this.strData.replace(
                    "根据提供的上下文信息，",
                    ""
                  );
                  this.explanation = this.strData;
                }

                this.scrollToBottom();
                processStream();
              });
            };
            processStream();
          })
          .finally(() => {
            // this.loading = false;
            this.copyButtonDisabled = false;
            this.submitButtonDisabled = false;
          });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    copyTranslation() {
      // 复制解释结果到剪贴板

      const explanationArea = document.getElementById("explanationArea");
      // 获取原始内容
      const originalContent = explanationArea.textContent;

      // 定义要追加的字符串
      const appendedText1 = "内容由Gxpcode提供的AI服务生成\n";
      const appendedText2 = "\n内容由Gxpcode提供的AI服务生成";

      // 将追加的字符串添加到原始内容中
      // explanationArea.value = appendedText1 + originalContent + appendedText2;

      // 创建一个临时的 textarea 元素
      const textarea = document.createElement("textarea");
      textarea.value = appendedText1 + originalContent + appendedText2;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");
      this.$message.success("翻译结果已复制");
    },
    toLishi() {
      this.$router.push({ path: "/CC/History" });
    },
    toNeirong() {
      this.$router.push({ path: "/CC/Riskdb" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fanyi-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .content-box {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    border: 1px solid #dddddd;
    display: flex;
    margin-top: 20px;
    .left-box,
    .right-box {
      width: 50%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
    .left-box {
      position: relative;
      .top-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .top-title {
          font-size: 18px;
          color: #222222;
          line-height: 40px;
        }
      }
      .fanyi-btn {
        position: absolute;
        width: 100%;
        bottom: 20px;
        right: 20px;
        text-align: right;
      }
    }
    .right-box {
      background-color: #f4f4f5;
      .top-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .top-title {
          font-size: 18px;
          color: #222222;
          line-height: 40px;
        }
      }
      .jieguo-input {
        :deep(.el-textarea__inner) {
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: #222; /* 自定义文本颜色 */
          line-height: 30px;
          padding: 0;
        }
      }
    }
  }
}
</style>
