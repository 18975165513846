<template>
  <div class="jieguo-input">
    <vue-markdown
      source="##**1.应用简述：**
**1.1翻译助手：** 提供制药专业英语的翻译服务。
**1.2变更风险评估：** 提供生产/研发相关的变更活动的风险识别，助力您把控变更可能产生的风险。
**1.3偏差调查指引：** 提供生产/研发相关的偏差的调查指引，为您提供偏差的可能原因以及调查项。
**1.4OOS调查指引：** 提供实验室结果超标的调查指引，为您提供OOS的可能原因以及调查项。
**1.5MDD调查指引：** 提供微生物结果偏差的调查指引，为您提供MDD的可能原因以及调查项。
##**2.使用注意事项：**
**2.1关于涉密信息：**如果涉及您个人或公司的涉密信息，请不要在应用中输入，应用可以在不需要涉密信息的情况下正常使用。
**2.1关于涉密信息：**如果涉及您个人或公司的涉密信息，请不要在应用中输入，应用可以在不需要涉密信息的情况下正常使用。
**2.1关于涉密信息：**如果涉及您个人或公司的涉密信息，请不要在应用中输入，应用可以在不需要涉密信息的情况下正常使用。
**2.1关于涉密信息：**如果涉及您个人或公司的涉密信息，请不要在应用中输入，应用可以在不需要涉密信息的情况下正常使用。
**2.1关于涉密信息：**如果涉及您个人或公司的涉密信息，请不要在应用中输入，应用可以在不需要涉密信息的情况下正常使用。
**2.2关于响应时间：**
2.2.1：翻译应用响应时间为瞬时响应（即输入即可获得输出）
2.2.2：“变更风险评估”响应时间一般为40-70秒之间（即输入后40-70秒可以获得输出）
2.2.3：“偏差调查指引”响应时间一般为20-30秒左右
2.2.4：“OOS调查指引”响应时间一般为20-30秒左右
2.2.5：“MDD调查指引”响应时间一般为20-30秒左右
##**3其它：**
3.1：暂时还没想好"
    ></vue-markdown>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "",

  components: { VueMarkdown },

  data() {
    return {};
  },
  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.jieguo-input {
  padding: 20px;
  font-size: 16px;
  line-height: 30px;
}
</style>
