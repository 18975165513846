<template>
  <div>
    <neirong :kuType="'3'"></neirong>
  </div>
</template>

<script>
import neirong from "../components/neirong.vue";
export default {
  name: "",
  components: { neirong },
};
</script>