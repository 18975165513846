<template>
    <div class="app-container home">
        跳转中......
    </div>
</template>

<script>
    export default {
        name: "LoginWx",
        data() {
            return {
                token:undefined
            };
        },
        created() {
            this.token = this.getParams("token");
            localStorage.setItem("token",this.token);
            this.$router.push({ path: "/home" });
        },
        methods:{
            getParams(str) {
                const url = window.location.href.split("?")[1];
                if (url !== undefined) {
                    var attrs = url.split("&");
                    for(var i in attrs) {
                        var value = attrs[i].split("=");
                        if (str === value[0]) {
                            return value[1];
                        }
                    }
                }
                return null;
            }
        }
    };
</script>

<style scoped lang="scss">

</style>

