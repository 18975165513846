<template>
  <!-- <div id="login-page">
    <div class="login-container">
      <img class="image" src="https://gxpresource.oss-cn-shenzhen.aliyuncs.com/login_img.png" />
      <div class="container">
        <img class="login_image" src="/pic/login_logo@2x.png" />
        <div class="title">登录</div>
        <el-form
                ref="loginForm"
                :model="loginForm"
                :rules="loginRules"
                label-width="80px"
        >
          <el-form-item label="手机号" prop="mobileNo">
            <el-input v-model="loginForm.mobileNo"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="verificationCode">
            <div class="code-container">
              <el-input v-model="loginForm.verificationCode"></el-input>
              <div class="code-btn-container">
                <el-button
                        v-if="!countdown"
                        @click.prevent="sendCode"
                        class="code"
                >发送验证码</el-button>
                <span v-else class="countdown">{{ countdown }}s</span>
              </div>
            </div>
          
          
           
          </el-form-item>
          <div class="btn-container">
            <el-button type="primary" @click.prevent="submitForm">登录</el-button>
            <el-button @click.prevent="wechatLogin">微信登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="text">琼ICP备19003923号</div>
  </div> -->
  <div class="login">
    <div class="login-weaper">
      <div class="login-left">
        <div class="login-left-text">您的AI工作助手</div>
      </div>
      <div class="login-right">
        <div class="login-main">
          <h4 class="login-title">欢迎登录</h4>
          <!-- <h6 class="login-title-h6">手机验证码登录</h6> -->
          <el-form
            class="el-form login-form"
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
          >
            <!-- <el-form-item prop="mobileNo" style="margin-left: 0px">
              <el-input
                type="text"
                maxlength="11"
                placeholder="请输入手机号"
                v-model="loginForm.mobileNo"
                clearable
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="margin-left: 0px" prop="verificationCode">
              <div class="yxm-box">
                <div class="yxm-input">
                  <el-input
                    type="text"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                    v-model="loginForm.verificationCode"
                    clearable
                    autocomplete="off"
                  ></el-input>
                </div>
                <div class="yxm-btn">
                  <div class="login-code">
                    <el-button @click.prevent="sendCode" v-if="!countdown">
                      发送验证码</el-button
                    >
                    <span v-else class="countdown">{{ countdown }}s</span>
                  </div>
                </div>
              </div>
            </el-form-item> -->

            <el-form-item style="margin: 10px 0px 0">
              <el-button
                class="login-submit login-submit1"
                @click="wechatLogin"
                type="primary"
              >
                <span class="login-text login-text1">微信登录</span>
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="login-submit1" @click="submitForm" disabled>
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="text">琼ICP备19003923号</div>
    <!-- <div class="vue-particles">
      <vue-particles
        color="#dedede"
        shapeType="star"
        linesColor="#dedede"
        hoverMode="grab"
        clickMode="push"
        style="height: 100%"
      ></vue-particles>
    </div> -->
  </div>
</template>

<script>
import { getMobileCode, login, wxLoginCode } from "@/api/index";

export default {
  data() {
    return {
      loginForm: {
        mobileNo: undefined,
        verificationCode: "",
      },
      loginRules: {
        mobileNo: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      countdown: 0,
      inviteForm: {
        invitationCode: "",
      },
      submit: {
        loading: false,
      },
    };
  },
  created() {
    this.inviteForm.invitationCode = this.getParams("invitationCode");
    if (this.inviteForm.invitationCode === null) {
      this.inviteForm.invitationCode = "";
    }
  },
  methods: {
    sendCode() {
      if (this.loginForm.mobileNo !== undefined) {
        getMobileCode(this.loginForm).then((response) => {
          console.log(response.data);
        });
        // 发送短信验证码逻辑
        // 倒计时开始
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    submitForm() {
      this.submit.loading = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // let token =
          //   "gxpcodeeyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJneHBjb2RlIiwib3Blbl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwidXNlcl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwiaWF0IjoxNjg5NDc0MDYyLCJleHAiOjE2OTAwNzg4NjJ9.HZ8owJiGQbmmAti9k-3m15bitrOIdO8K83WpCZZTc68";
          // localStorage.setItem("token", token);
          // this.$router.push({ path: "/" });
          login(this.loginForm).then((response) => {
            let token = response.MobileBody.token;
            localStorage.setItem("token", token);
            this.$router.push({ path: "/home" });
          });
        } else {
          return false;
        }
      });
    },
    wechatLogin() {
      // alert(this.inviteForm.invitationCode);
      wxLoginCode(this.inviteForm).then((response) => {
        let loginUrl = response.MobileBody.codeUrl;
        window.location.href = loginUrl;
      });
    },
    getParams(str) {
      const url = window.location.href.split("?")[1];
      if (url !== undefined) {
        var attrs = url.split("&");
        for (var i in attrs) {
          var value = attrs[i].split("=");
          if (str === value[0]) {
            return value[1];
          }
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
// #login-page {
//   width: 100%;
//   height: 100vh;
//   background-image: url("https://gxpresource.oss-cn-shenzhen.aliyuncs.com/img_bg.png");
//   background-size: cover;
//   background-position: center;
// }

// .image {
//   height: 90%;
//   margin: 20px;
//   flex: 1;
// }

// .container {
//   float: left;
//   margin: 20px;
//   flex: 1;
// }

// .login_image {
//   height: 50px;
//   width: 126px;
//   margin: 0 25%;
//   margin-bottom: 40px;
//   text-align: center;
// }

// .title {
//   font-family: HarmonyOS_Sans_SC_Bold;
//   font-size: 20px;
//   /* font-weight: bold; */
//   line-height: 28px;
//   letter-spacing: 0px;
//   color: #000000;
//   margin: 10px;
// }

// .el-form-item {
//   margin: 30px 0;
// }

// .el-input {
//   width: 90%;
// }

// .el-button {
//   width: 100px;
// }

// .code {
//   margin: 0 25px 0 15px;
//   padding: 12px;
// }

// .login-container {
//   width: 60%;
//   margin: 75px 20%;
//   float: left;
//   background-color: white;
//   height: 400px;
//   display: flex;
// }

// .btn-container {
//   margin-top: 20px;
//   text-align: center;
// }

// .code-container {
//   display: flex;
//   align-items: center;
// }

// .countdown {
//   margin-left: 10px;
//   color: #999;
// }

// .wechat-login-container {
//   margin-top: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .text {
//   font-size: 14px;
//   width: 100%;
//   height: 20px;
//   text-align: center;
//   bottom: 20px;
//   position: relative;
// }

// @media (max-width: 768px) {
//   /* 在屏幕宽度小于等于768px时应用的样式 */
//   #login-page {
//     background-size: contain;
//   }
//   .login-container {
//     width: 100%;
//     margin: 0;
//     height: auto;
//     flex-direction: column;
//   }
//   .image {
//     height: 300px;
//     margin: 20px 0;
//     flex: none;
//   }
//   .container {
//     float: none;
//     margin: 20px 0;
//     flex: none;
//   }
//   .title {
//     font-size: 16px;
//     margin-left: 20px;
//   }
//   .el-input {
//     width: 100%;
//   }
//   .el-button {
//     width: 100%;
//     margin: 10px 0;
//   }
//   .code {
//     margin: 0;
//   }
//   .btn-container {
//     margin-top: 10px;
//   }
//   .text {
//     font-size: 12px;
//   }
// }

.login {
  height: 100%;
  width: 100%;
  overflow: hidden;

  position: relative;
  // .vue-particles {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
  //   background-image: url("https://gxpresource.oss-cn-shenzhen.aliyuncs.com/img_bg.png");
  //   background-size: cover;
  //   background-position: center;
  // }
  .text {
    position: absolute;
    bottom: 60px;
    left: 75%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: var(--prev-color-text-primary);
    z-index: 999;
  }
  .login-weaper {
    width: 100%;
    height: 100%;

    display: flex;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    border: none;

    .login-left {
      width: 50%;
      height: 100%;
      // font-size: 16px;
      // color: var(--prev-color-text-white);
      position: relative;
      // background-color: var(--prev-color-primary);
      // background-image: url("https://gxpresource.oss-cn-shenzhen.aliyuncs.com/login_img.png");
      // background-size: cover;
      // background-position: center;
      // display: flex;
      // flex-direction: column;
      // border-top-left-radius: 4px;
      // border-bottom-left-radius: 4px;
      background-color: #cdebd7;
      background-image: url("./../assets/imgs/icon_dh_logo@2x.png");
      background-repeat: no-repeat;
      background-position: 180px 190px;
      background-size: 275px 48px;
      .login-left-text {
        width: 500px;
        height: 46px;
        font-size: 34px;
        color: #222222;
        letter-spacing: 10px;
        position: absolute;
        bottom: 240px;
        left: 180px;

        // flex: 1;
        // overflow: hidden;
        // position: relative;
        // z-index: 1;
        // display: flex;
        // align-items: center;
        // padding: 80px 45px;
      }
    }
    .login-right {
      width: 50%;
      padding: 20px;
      position: relative;
      align-items: center;
      display: flex;
      background-color: var(--prev-bg-white);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      .login-main {
        margin: 0 auto;
        width: 400px;
        height: 455px;
        .login-title {
          color: var(--prev-color-primary);
          margin-bottom: 40px;
          font-size: 40px;
          color: #222222;
          text-align: center;
          letter-spacing: 4px;
        }
        .login-title-h6 {
          font-size: 18px;
          color: #222222;
        }
        .login-form {
          margin: 10px 0;
          i {
            color: var(--prev-color-text-primary);
          }
          .yxm-box {
            display: flex;
            .yxm-input {
              width: calc(100% - 112px);
            }
          }
          .countdown {
            display: inline-block;
            width: 112px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
          .el-form-item {
            margin-bottom: 20px !important;
            .login-code {
              display: flex;
              align-items: center;
              justify-content: space-around;
              user-select: none;
            }
            .login-submit1 {
              width: 100%;
              letter-spacing: 2px;

              font-size: 18px;
              .login-text {
                font-size: 18px;
                color: #fff;
              }
              .login-text1 {
                // margin-left: 24px;
                padding-left: 30px;
                color: #222222;
                background-image: url("../assets/imgs/icon_weixin@2x.png");
                background-size: 24px 26px;
                background-position: 0 center;

                background-repeat: no-repeat;
              }
            }
            .login-submit {
              // background-image: url("../assets/imgs/icon_weixin@2x.png");
              // background-size: 24px 26px;
              // background-repeat: no-repeat;
              // background-position: 144px center;
            }
          }
        }
      }
    }
  }
}
</style>
