<template>
  <div class="shouye-box">
    <h4 class="obj-title">欢迎使用 GxpCode!</h4>

    <ul class="shouye-ul">
      <li class="shouye-li shouye-li1" @click="to(1)">翻译助手</li>
      <li class="shouye-li shouye-li2" @click="to(2)">变更风险评估</li>
      <li class="shouye-li shouye-li3" @click="to(3)">偏差调查指引</li>
      <li class="shouye-li shouye-li4" @click="to(4)">OOS调查指引</li>
      <li class="shouye-li shouye-li5" @click="to(5)">MDD调查指引</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",

  components: {},

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
    to(i) {
      switch (i) {
        case 1:
          this.$router.push({ path: "/Translation" });
          break;
        case 2:
          this.$router.push({ path: "/CC" });
          break;
        case 3:
          this.$router.push({ path: "/Dev", });
          break;
        case 4:
          this.$router.push({ path: "/OOS",});
          break;
        case 5:
          this.$router.push({ path: "/MDD", });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shouye-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .shouye-ul {
    display: flex;
    flex-wrap: wrap;
    .shouye-li {
      width: 385px;
      height: 220px;
      background-color: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 24px;
      background-repeat: no-repeat;
      background-position: center 60px;
      background-size: 44px 42px;
      border: 2px solid #ddd;
      font-size: 24px;
      color: #222222;
      padding-top: 135px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 30px;
      margin-right: 30px;
      &:hover {
        border: 2px solid #222;
      }
    }
    .shouye-li1 {
      background-image: url("./../assets/imgs/icon_fanyizhushou@2x.png");
    }
    .shouye-li2 {
      background-image: url("./../assets/imgs/icon_biangengguanli@2x.png");
    }
    .shouye-li3 {
      background-image: url("./../assets/imgs/icon_piancha@2x.png");
    }
    .shouye-li4 {
      background-image: url("./../assets/imgs/icon_oos@2x.png");
    }
    .shouye-li5 {
      background-image: url("./../assets/imgs/icon_MDD@2x.png");
    }
  }
}
</style>
