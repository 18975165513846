<template>
  <div class="fanyilishi-box p-20">
    <p class="obj-title">
      <span>{{ kuType == 0 ? "变更风险库" : "调查库" }}</span>
      <el-button type="primary" size="medium" plain @click="back()"
        >返回</el-button
      >
    </p>
    <div class="table-box">
      <el-table
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ backgroundColor: '#F4F4F5', color: '#222' }"
      >
        <el-table-column type="index" label="编号" width="100">
        </el-table-column>
        <el-table-column
          prop="content"
          :label="kuType == 0 ? '风险点' : '调查项'"
        >
        </el-table-column>
        <el-table-column prop="create_time" width="200" label="创建时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleCopy(scope.row.content)" type="text"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="param.pageSize"
        layout=" prev, pager, next, sizes,jumper"
        :total="total"
        class="custom-pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getContentList } from "@/api/order";

export default {
  name: "",
  components: {},
  props: {
    kuType: {
      type: String, // 类型校验
      required: true, // 是否必传
      default: "", // 默认值
    },
  },
  data() {
    return {
      dialogVisible: false,
      textarea: "",
      tableData1: [],
      tableData: [],
      param: {
        page: 1,
        pageSize: 10,
        type: "",
      },
      backPath: "",
    };
  },

  created() {
    if (this.kuType == "1") {
      this.backPath = "/Dev";
    } else if (this.kuType == "2") {
      this.backPath = "/OOS";
    } else if (this.kuType == "3") {
      this.backPath = "/MDD";
    }else if (this.kuType == "0") {
      this.backPath = "/CC";
    }
  },

  mounted() {
    this.getTableData();
  },

  methods: {
    getTableData() {
      this.param.type = this.kuType;
      getContentList(this.param).then((res) => {
        this.tableData = res.MobileBody.contentList;
        this.total = res.MobileBody.totalCount;
      });
    },
    detail(data) {
      this.dialogVisible = true;
    },
    handleCopy(data) {
      // 创建一个临时的 textarea 元素
      const textarea = document.createElement("textarea");
      textarea.value = "内容由Gxpcode提供的AI服务生成\n" + data;
      document.body.appendChild(textarea);

      // 选中文本
      textarea.select();
      textarea.setSelectionRange(0, 99999); // 兼容移动设备

      // 复制到剪贴板
      document.execCommand("copy");

      // 移除临时元素
      document.body.removeChild(textarea);

      // 提示用户
      this.$message({
        message: "已复制到剪贴板",
        type: "success",
      });
    },
    handleAction(action, data) {
      switch (action) {
        case "delete":
          this.$message("点击了删除");
          break;
        case "view":
          this.$message("点击了查看");
          break;
        case "export":
          this.$message("点击了导出");
          break;
        default:
          break;
      }
    },
    handleClick(data) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    back() {
      this.$router.push({ path: this.backPath });
    },
  },
};
</script>

<style lang="scss" scoped>
.fanyilishi-box {
  .table-box {
    margin-top: 20px;
    border-radius: 8px;

    overflow: hidden; /* 隐藏超出部分 */
    border: 1px solid #ddd;

    .el-table {
      border-radius: 8px; /* 表格圆角 */
    }

    .el-table__header-wrapper,
    .el-table__body-wrapper {
      border-radius: 8px; /* 表头和表体圆角 */
    }
  }

  .pop-form {
    p {
      margin-top: 30px;
      color: #222;
      margin-bottom: 20px;
    }
    .pop-flex {
      display: flex;
      justify-content: space-between;

      .pop-flex-item {
        width: calc(50% - 10px);
      }
    }
  }
  .pop-box {
    .pop-form {
      width: 50%;
      padding-right: 20px;
      box-sizing: border-box;
      p {
        margin-top: 30px;
        color: #222;
        margin-bottom: 20px;
      }
      .pop-flex {
        display: flex;
        justify-content: space-between;

        .pop-flex-item {
          width: calc(50% - 10px);
        }
      }
    }
    .jieguo-box {
      position: relative;
      width: 50%;
      background-color: #f4f4f5;
      border: radius 16px;
      .jieguo-title {
        font-size: 18px;
        color: #222222;
        line-height: 40px;
        border-bottom: 1px solid #dddddd;
      }
      .biangeng-btn {
        position: absolute;
        top: 70px;
        right: 20px;
        z-index: 99;
      }
    }
  }
  .text-cell {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制显示两行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5; /* 调整行高 */
  }
}
</style>
<style>
.el-dialog__footer {
  text-align: center !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #222;
  color: #fff;
}
</style>
