<template>
  <div class="fanyilishi-box p-20">
    <p class="obj-title">
      <span>历史数据</span>
      <el-button type="primary" plain @click="back()">返回</el-button>
    </p>
    <div class="table-box">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ backgroundColor: '#F4F4F5', color: '#222' }"
      >
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="word" label="内容" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="detail(scope.row)" style="cursor: pointer">
              {{ scope.row.word }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="param.pageSize"
        layout=" prev, pager, next, sizes,jumper"
        :total="total"
        class="custom-pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="历史数据详情"
      :visible.sync="dialogVisible"
      width="60%"
      @close="handleClose"
    >
      <div class="pop-form">
        <div>
          <p style="margin-top: 10px">输入内容</p>
          <el-input
            type="textarea"
            :rows="6"
            placeholder=""
            v-model="detailData.word"
            style="width: 100%; line-height: 30px"
            class="no-resize"
          >
          </el-input>
        </div>
        <div class="pop-flex">
          <div class="pop-flex-item">
            <p>业务使用场景</p>
            <el-input
              placeholder=""
              v-model="detailData.context"
              style="width: 100%; line-height: 30px"
              class="no-resize"
            >
            </el-input>
          </div>
          <div class="pop-flex-item">
            <p>业务模块</p>
            <el-input
              placeholder=""
              v-model="detailData.scenario"
              style="width: 100%; line-height: 30px"
            >
            </el-input>
          </div>
        </div>
        <div>
          <p>解释与翻译</p>
          <el-input
            type="textarea"
            :rows="6"
            placeholder=""
            v-model="detailData.explanation_result"
            style="width: 100%; line-height: 30px"
            class="no-resize"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTranslateLog } from "@/api/order";

export default {
  name: "",
  components: {},

  data() {
    return {
      dialogVisible: false,
      total: 0,
      param: {
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      detailData: {},
    };
  },

  created() {},

  mounted() {
    this.getLishiData();
  },

  methods: {
    handleClose(){},
    back() {
      this.$router.push({ path: "/Translation" });
    },
    detail(data) {
      this.detailData = data;
      this.dialogVisible = true;
    },
    getLishiData() {
      getTranslateLog(this.param).then((res) => {
        console.log(res);
        this.tableData = res.MobileBody.logList;
        this.total = res.MobileBody.totalCount;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.param.pageSize = val;
      this.param.page = 1;
      this.getLishiData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.param.page = val;
      this.getLishiData();
    },
  },
};
</script>

<style lang="scss" scoped>
.fanyilishi-box {
  .table-box {
    margin-top: 20px;
    border-radius: 8px;

    overflow: hidden; /* 隐藏超出部分 */
    border: 1px solid #ddd;

    .el-table {
      border-radius: 8px; /* 表格圆角 */
    }

    .el-table__header-wrapper,
    .el-table__body-wrapper {
      border-radius: 8px; /* 表头和表体圆角 */
    }
  }

  .pop-form {
    p {
      margin-top: 30px;
      color: #222;
      margin-bottom: 20px;
    }
    .pop-flex {
      display: flex;
      justify-content: space-between;

      .pop-flex-item {
        width: calc(50% - 10px);
      }
    }
  }
}
</style>
<style>
.el-dialog__footer {
  text-align: center !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #222;
  color: #fff;
}
</style>
