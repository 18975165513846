<template>
  <div class="h-100-1">
    <piancha :kuType="'2'"></piancha>
  </div>
</template>

<script>
import piancha from "../components/pianchaCom.vue";
export default {
  name: "",
  components: { piancha },
};
</script>