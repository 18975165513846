import axios from "axios";
import {Notification, MessageBox, Message, Loading} from 'element-ui'

const http = axios.create({
    //通用请求的地址
    baseURL: 'https://api.gxpcode.com',
    // baseURL: 'http://localhost:82',
    timeout: 60000, //超时时间
})

//添加请求拦截器
http.interceptors.request.use(function (config) {
    // 获取localStorage中的token
    const token = localStorage.getItem('token');
    // 将token添加到请求头中
    if (token !== undefined) {
        config.headers['X-Gxpcode-Access-Token'] = token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

//添加响应拦截器
http.interceptors.response.use(function (response) {
    const code = response.data.MobileHead.Code;
    //成功
    if (code === 1) {
        return response.data.MobileBody === undefined ? response.data : response.data;
    } else if (code < -1) {
        location.href = '/Login';
        // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        // }).then(() => {
        //
        // }).catch(() => {
        //
        // });
    } else {
        Message({message: response.data.MobileHead.Message, type: 'error', duration: 5 * 1000});
        return Promise.reject(response.data.MobileHead.Message);
    }
    return response;
}, function (error) {
    Message({message: error, type: 'error', duration: 5 * 1000})
    return Promise.reject(error);
});

export default http