<template>
  <div class="zhanghu-box p-20">
    <p class="obj-title">
      <span>账户</span>
    </p>
    <div class="content-box f-c-b">
      <ul class="menu-box">
        <li class="menu-item" :class="activeClass[0]" @click="menuClick(0)">
          个人信息
        </li>
        <li class="menu-item" :class="activeClass[1]" @click="menuClick(1)">
          账户
        </li>
        <li class="menu-item" :class="activeClass[2]" @click="menuClick(2)">
          账单记录
        </li>
        <li class="menu-item" :class="activeClass[3]" @click="menuClick(3)">
          充值记录
        </li>
      </ul>
      <div class="right-box">
        <div class="user-info" v-if="activeClass[0] == 'active'">
          <p class="input-title">名称</p>
          <el-input
            placeholder=""
            v-model="user.nickName"
            style="width: 100%; line-height: 30px"
            class="transparent-input no-resize"
          >
          </el-input>
          <p class="input-title">头像</p>
          <div class="toux">
            <img :src="user.headImg" alt="头像" />
          </div>

          <p class="input-title">绑定手机</p>
          <el-input
            placeholder=""
            v-model="user.mobileNo"
            style="width: 100%; line-height: 30px"
            class="transparent-input no-resize"
          >
          </el-input>
          <p class="input-title">
            邀请链接 <span class="yaoqing-tip">邀请使用微信登录成功后有奖</span>
          </p>
          <el-input
            placeholder=""
            v-model="user.link"
            style="width: calc(100% - 70px); line-height: 30px"
            class="transparent-input"
          >
          </el-input>
          <el-button type="primary" plain @click="copyLink">复制</el-button>
        </div>

        <div class="yu-e" v-if="activeClass[1] == 'active'">
          <p class="input-title">账户余额</p>
          <el-input
            placeholder=""
            v-model="user.money"
            style="width: 100%; line-height: 30px"
            class="transparent-input"
          >
          </el-input>
          <div class="yu-e-btn">
            <el-button type="primary" plain @click="dialogVisible = true"
              >购买会员</el-button
            >
          </div>
        </div>
        <div class="zhangdan-box" v-if="activeClass[2] == 'active'">
          <p class="input-title">账单记录</p>
          <el-table
            :data="countData"
            style="width: 100%"
            class="transparent-table"
          >
            <el-table-column
              prop="createTime"
              label="时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="金额" show-overflow-tooltip>
              <template #default="scope"> ￥{{ scope.row.money }} </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="zhangdan-box" v-if="activeClass[3] == 'active'">
          <p class="input-title">充值记录</p>
          <el-table
            :data="moneyData"
            style="width: 100%"
            class="transparent-table"
          >
            <el-table-column
              prop="orderId"
              label="订单号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="下单时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="金额" show-overflow-tooltip>
              <template slot-scope="scope">
                ￥{{ scope.row.realMoney }}
              </template>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ getStatusDesc(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="payTime"
              label="支付时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="购买会员" width="400px">
      <div class="huiyuan-box">
        <div
          class="huiyuan-item"
          :class="selectedAmount == '20' ? 'gm-bg' : ''"
          @click="gm(20)"
        >
          <p class="jin-e">￥ <span>20</span></p>
          <p class="huiyuan-text">月会员</p>
        </div>
        <div
          class="huiyuan-item"
          :class="selectedAmount == '200' ? 'gm-bg' : ''"
          @click="gm(200)"
        >
          <p class="jin-e">￥ <span>200</span></p>
          <p class="huiyuan-text">年会员</p>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAmount">去充值</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 微信扫码支付 -->
    <el-dialog title="微信扫码支付" :visible.sync="QrDialogVisible">
      <div style="text-align: center">
        <el-row class="button-invite" style="text-align: center">
          <el-col>
            <img :src="qrcodeUrl" alt="二维码" />
          </el-col>
          <span class="button-font-invite"
            >用微信扫码支付{{ selectedAmount }}元</span
          >
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginOut, userInfo, getMobileCode, bindMobile } from "@/api/index";
import {
  wxPayPre,
  queryOrder,
  getOrderList,
  getBalanceList,
} from "@/api/order";
import ClipboardJS from "clipboard";
import QRCode from "qrcode";

export default {
  name: "",

  components: {},

  data() {
    return {
      qrcodeUrl: null,

      dialogVisible: false,

      QrDialogVisible: false,

      activeClass: ["active", "", "", ""],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
        },
      ],
      user: {
        headImg: "",
        nickName: "",
        mobileNo: "",
        codeUrl: "",
      },
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      queryBalanceParams: {
        page: 1,
        pageSize: 10,
        type: 2,
      },
      countData: [],
      moneyData: [],
      form: {
        money: undefined,
        orderId: undefined,
        rechargeType: undefined,
      },
      customAmount: 20,
      selectedAmount: 20,
    };
  },

  created() {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getStatusDesc(status) {
      if (status === 0) {
        return "未付款";
      } else if (status === 1) {
        return "已付款";
      } else if (status === 2) {
        return "已取消";
      } else if (status === 3) {
        return "已完成";
      } else if (status === 4) {
        return "已退款";
      } else if (status === 5) {
        return "已删除";
      } else {
        return "";
      }
    },
    gm(data) {
      this.selectedAmount = data;
      this.customAmount = data;
    },
    startPaymentPolling() {
      this.paymentInterval = setInterval(() => {
        this.checkPaymentStatus();
      }, 3000); // 每3秒轮询一次支付状态，根据实际情况调整间隔时间
      const timeoutDuration = 60000; // 设置超时时间为60秒，根据实际情况调整
      this.timeoutInterval = setTimeout(() => {
        clearInterval(this.paymentInterval); // 停止轮询
        // 处理超时逻辑，例如显示超时提示信息
      }, timeoutDuration);
    },
    checkPaymentStatus() {
      queryOrder(this.form)
        .then((response) => {
          const status = response.MobileBody.status;
          if (status === 1) {
            this.$message.success("支付成功");
            this.handlePaymentSuccess();
          } else if (status === 2) {
            this.$message.error("已取消支付");
            this.handlePaymentSuccess();
          } else {
            // 支付失败或其他状态，根据实际情况处理
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handlePaymentSuccess() {
      clearTimeout(this.timeoutInterval); // 清除超时定时器
      clearInterval(this.paymentInterval); // 停止轮询
      // this.init();
      this.QrDialogVisible = false; // 关闭弹窗
      this.form.orderId = null;
      this.selectedAmount = null;
      this.customAmount = null;
    },
    generateQRCode(url) {
      QRCode.toDataURL(url)
        .then((qrcodeUrl) => {
          this.qrcodeUrl = qrcodeUrl;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    confirmAmount() {
      //if (this.selectedAmount != null || this.customAmount != null) {
      this.form.money = this.customAmount * 100;
      wxPayPre(this.form).then((response) => {
        this.generateQRCode(response.MobileBody.codeUrl);
        this.dialogVisible = false;
        this.QrDialogVisible = true;
        this.form.orderId = response.MobileBody.orderId;
        this.startPaymentPolling();
      });
      // } else {
      //   this.$message.error("请选择一个金额或输入自定义金额");
      // }
    },
    getUserInfo() {
      userInfo().then((res) => {
        console.log(res);

        this.user.nickName = res.MobileBody.nickName;
        this.user.mobileNo = res.MobileBody.mobileNo;
        this.user.money = res.MobileBody.money;
        this.user.link =
          window.location.host +
          "/Login?invitationCode=" +
          res.MobileBody.invitationCode;

        if (res.MobileBody.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }

        getOrderList(this.queryParams).then((response) => {
          this.moneyData = response.MobileBody.orderList;
        });
        getBalanceList(this.queryBalanceParams).then((response) => {
          this.countData = response.MobileBody.balanceList;
        });
      });
    },
    menuClick(index) {
      this.activeClass = ["", "", "", ""];
      this.activeClass[index] = "active";
    },
    copyLink() {
      const host = this.user.link;
      const clipboard = new ClipboardJS(".button-in", {
        text: () => host,
      });

      clipboard.on("success", () => {
        this.$message.success("链接已复制");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        this.$message.error("复制链接失败");
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  margin-top: 20px;
  width: 100%;
  height: 100% !important;
  background-color: #fff;
  .menu-box {
    width: 200px;
    height: 100%;
    .menu-item {
      width: 200px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      border-radius: 8px;

      color: #222222;
      padding-left: 20px;
      box-sizing: border-box;
      &:hover {
        background: #f4f4f5;
      }
    }
    .active {
      background: #f4f4f5;
    }
  }
  .right-box {
    width: calc(100% - 300px);
    .user-info {
      .toux {
        width: 110px;
        height: 110px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .yaoqing-tip {
        color: #e02020;
        margin-left: 20px;
        height: 100%;
      }
    }
    .yu-e {
      .yu-e-btn {
        margin-top: 30px;
      }
    }
  }
}
.huiyuan-box {
  display: flex;
  justify-content: space-around;
  .huiyuan-item {
    width: 120px;
    height: 140px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #dddddd;
    text-align: center;
    padding-top: 30px;
    box-sizing: border-box;
    background-size: 100% 100%;
    span {
      font-weight: bold;
      font-size: 40px;
      color: #222222;
    }
    .huiyuan-text {
      margin-top: 20px;
    }
  }
  .gm-bg {
    background-image: url("../assets/imgs/img_xuanzhong@2x.png");
  }
}
</style>
<style>
/* 设置表头背景透明 */
.el-table thead {
  background: #f4f4f5;
}
</style>
