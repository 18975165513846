<template>
  <div class="fanyi-box">
    <p class="obj-title">
      <span>制药专业术语翻译助手</span>
      <el-button type="primary" plain @click="toLishi()">历史数据</el-button>
    </p>

    <div class="content-box">
      <div class="left-box">
        <div class="top-box">
          <div class="top-title">
            <span class="top-title-text">输入区域</span>
            <el-radio-group
              v-model="radio1"
              size="small"
              @click.native="modelChange"
            >
              <el-radio label="1" disabled>基础模式</el-radio>
              <el-radio label="2" disabled>增强模式</el-radio>
              <el-radio label="3" disabled>专业模式</el-radio>
            </el-radio-group>
          </div>
          <div class="radio-group-box">
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="en">英文到中文</el-radio-button>
              <el-radio-button label="zh">中文到英文</el-radio-button>
              <el-radio-button label="translate"
                >长文本中英互译</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="input-box">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="需要解释或翻译的词语/句子填写到这里，如果翻译结果感觉不行，可以再点击翻译按钮一次，会出现可能不一样的结果"
            v-model="prompt.word"
            style="width: 100%; line-height: 30px"
            class="no-resize"
          >
          </el-input>
        </div>
        <div class="input-box">
          <el-input
            placeholder="在这里输入这个被翻译的词语/句子是在什么剂型或者业务模块下使用的"
            v-model="prompt.context"
            style="width: 100%"
          >
          </el-input>
        </div>
        <div class="input-box">
          <el-input
            placeholder="在这里输入这个被翻译的词语/句子在什么具体业务场景中使用的"
            v-model="prompt.scenario"
            style="width: 100%"
          >
          </el-input>
        </div>
        <div class="fanyi-btn">
          <el-button
            type="primary"
            @click="submitForm"
            :disabled="submitButtonDisabled"
            id="submitButton"
            >翻译</el-button
          >
        </div>
      </div>
      <div
        class="right-box"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        <div class="top-box">
          <div class="top-title">
            解释与翻译<span style="font-size: 14px"
              >(内容由Gxpcode提供的AI服务生成)</span
            >
          </div>
          <el-button @click="copyTranslation" :disabled="copyButtonDisabled"
            >复制</el-button
          >
        </div>
        <!-- <el-input
          readonly
          id="explanationArea"
          type="textarea"
          :rows="16"
          placeholder="这里会出现解释结果，请等候…"
          class="jieguo-input no-resize"
          v-model="explanation"
        ></el-input> -->
        <div class="jieguo-input" ref="messageContainer" id="explanationArea">
          <vue-markdown :source="explanation"></vue-markdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
  name: "",

  components: { VueMarkdown },

  data() {
    return {
      radio1: "1",
      radio: "en",
      homeThree: [{}, {}],

      isMobile: false,
      prompt: {
        language: "en",
        word: null,
        context: null,
        scenario: null,
        explanationWord: "",
      },
      divStyle: {
        overflow: "hidden",
      },
      explanation: "",
      submitButtonColor: "#1a73e8",
      showSpinner: false,
      copyButtonDisabled: true,
      submitButtonDisabled: false,

      loading: false,
      streamingData: [],
      rules: {
        word: [
          { required: true, message: "词语或者句子不能为空", trigger: "blur" },
        ],
      },
    };
  },

  created() {},

  mounted() {},

  methods: {
    modelChange() {
      this.$message({
        message: "当前仅向特定用户开放",
        type: "warning",
      });
    },
    submitForm() {
      if (this.prompt.word == null) {
        this.$message({
          message: "词语或句子不能为空",
          type: "warning",
        });
        return;
      }
      // this.loading = true;
      // translate(this.prompt).then((response) => {
      //   this.explanation = response.MobileBody.result;
      //   console.log(response);
      //   this.loading = false;
      //   this.copyButtonDisabled = false;
      // });

      // if ((this.radio = "英文到中文")) {
      //   this.prompt.language = "en";
      // } else if ((this.radio = "中文到英文")) {
      //   this.prompt.language = "zh";
      // } else if ((this.radio = "长文本中英互译")) {
      //   this.prompt.language = "translate";
      // }

      this.streamingData = [];
      if (this.prompt.word !== "") {
        this.loading = true;
        this.scrollToBottom();
        // this.prompt.word = '';
        let start = true;
        this.copyButtonDisabled = true;
        this.submitButtonDisabled = true;

        this.loading = true;
        fetch("https://api.gxpcode.com/openai/promptAiFlow", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Gxpcode-Access-Token": localStorage.getItem("token"),
          },
          body: JSON.stringify(this.prompt),
        })
          .then((response) => response.body)
          .then((stream) => {
            this.loading = false;

            const reader = stream.getReader();
            const processStream = () => {
              reader.read().then(({ done, value }) => {
                if (done) {
                  return;
                }
                const bytes = new Uint8Array(value);
                const decoder = new TextDecoder();
                // const str = decoder.decode(bytes).replace(/\n/g, "");
                const str = decoder.decode(bytes);
                if (str == "-7") {
                  location.href = "/Login";
                }

                this.streamingData.push(str);
                this.strData = this.streamingData.join("");
                if (start) {
                  this.explanation = this.strData;
                  // this.explanation = marked(this.explanation);
                  start = false;
                } else {
                  this.strData = this.strData.replace(
                    "根据提供的背景信息，",
                    ""
                  );
                  this.strData = this.strData.replace(
                    "根据提供的上下文信息，",
                    ""
                  );
                  this.explanation = this.strData;
                }

                this.scrollToBottom();
                processStream();
              });
            };
            processStream();
          })
          .finally(() => {
            // this.loading = false;
            this.copyButtonDisabled = false;
            this.submitButtonDisabled = false;
          });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    copyTranslation() {
      // 复制解释结果到剪贴板

      const explanationArea = document.getElementById("explanationArea");
      // 获取原始内容
      const originalContent = explanationArea.textContent;

      // 定义要追加的字符串
      const appendedText1 = "内容由Gxpcode提供的AI服务生成\n";
      const appendedText2 = "\n内容由Gxpcode提供的AI服务生成";

      // 将追加的字符串添加到原始内容中
      // explanationArea.value = appendedText1 + originalContent + appendedText2;

      // 创建一个临时的 textarea 元素
      const textarea = document.createElement("textarea");
      textarea.value = appendedText1 + originalContent + appendedText2;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");
      this.$message.success("翻译结果已复制");
    },
    toLishi() {
      this.$router.push({ path: "/Translation/History" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fanyi-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .content-box {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    border: 1px solid #dddddd;
    display: flex;
    margin-top: 20px;
    .left-box,
    .right-box {
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
    .left-box {
      width: 62%;
      position: relative;
      .top-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .top-title {
          font-size: 18px;
          color: #222222;
          line-height: 40px;
        }
      }
      .input-box {
        margin-top: 20px;
      }
      .fanyi-btn {
        position: absolute;
        width: 100%;
        bottom: 20px;
        right: 20px;
        text-align: right;
      }
    }
    .right-box {
      width: 38%;
      background-color: #f4f4f5;
      border-bottom-right-radius: 24px;
      border-top-right-radius: 24px;

      .top-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .top-title {
          font-size: 18px;
          color: #222222;
          line-height: 40px;
        }
      }
      .jieguo-input {
        :deep(.el-textarea__inner) {
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: #222; /* 自定义文本颜色 */
          line-height: 30px;
          padding: 0;
        }
      }
    }
  }
}
</style>
