<template>
  <div class="jieguo-input lx-box">
    <p>关于我们</p>
    <p>致力于为药品生产/研发同行提供高效工作效率应用的小团队。</p>
    <p>联系方式</p>
    <p>联系邮箱：zhonghe1991@qq.com</p>
    <p class="erweima">微信号：GxpCode微信二维码：</p>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "",

  components: {},

  data() {
    return {
      explanation:
        "##**关于我们**致力于为药品生产/研发同行提供高效工作效率应用的小团队。##**联系方式**联系邮箱：zhonghe1991@qq.com微信号：GxpCode微信二维码：",
    };
  },

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.lx-box {
  padding: 20px;
  font-size: 16px;
  line-height: 30px;
  .erweima {
    width: 100%;
    height: 260px;
    background-image: url("../assets/imgs/lxwm.jpeg");
    background-size: 200px 260px;
    background-position: 200px 0;
    background-repeat: no-repeat;
  }
}
</style>
