import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Index from '../layout/index';
import Chat from '../views/Chat';
import Count from '../views/Count';
import Application from '../views/Application';
import Change from '../views/Change';
import Knowledge from '../views/Knowledge';
import Market from '../views/Market';
import Login from '../views/Login';
import LoginWx from "../views/LoginWx";
// import Help from "../views/Help";
import Feedback from "../views/Feedback";
import Setting from "../views/Setting";
import Prompt from "../views/Prompt";
import home from "../views/home.vue";
import Translation from "../views/Translation.vue";
import FangyiLishi from "../views/FangyiLishi.vue";
import BiangengGuanli from "../views/BiangengGuanli.vue";
import BiangengLishi from "../views/BiangengLishi.vue";
import BiangengNeirong from "../views/BiangengNeirong.vue";
import Zhanghu from "../views/Zhanghu.vue";
import Piancha from "../views/Piancha.vue";
import OosDc from "../views/OosDc.vue";
import MDD from "../views/MDD.vue";

import PianchaLishi from "../views/PianchaLishi.vue";
import OOSLishi from "../views/OOSLishi.vue";
import MDDLishi from "../views/MDDLishi.vue";

import PianchaNeirong from "../views/PianchaNeirong.vue";
import OOSNeirong from "../views/OOSNeirong.vue";
import MDDNeirong from "../views/MDDNeirong.vue";
import ContactUs from "../views/ContactUs.vue";
import Help from "../views/Help1.vue";













Vue.use(VueRouter);

/*//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};*/



const routes = [
  {
    path: '/',
    component: Index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: home,
        meta: { selectedMenu: 'home' }
      },
      {
        path: '/Translation',
        component: Translation,
        meta: { selectedMenu: 'Translation' },
      },
      {
        path: 'Translation/History',
        component: FangyiLishi,
        meta: { selectedMenu: 'FangyiLishi' }
      },
      {
        path: '/CC',
        component: BiangengGuanli,
        meta: { selectedMenu: 'BiangengGuanli' }
      },
      {
        path: '/CC/History',
        component: BiangengLishi,
        meta: { selectedMenu: 'BiangengLishi' }
      },
      {
        path: '/CC/Riskdb',
        component: BiangengNeirong,
        meta: { selectedMenu: 'BiangengNeirong' }
      },
      {
        path: '/account',
        component: Zhanghu,
        meta: { selectedMenu: 'Zhanghu' }
      },
      {
        path: '/Dev',
        component: Piancha,
        meta: { selectedMenu: 'Piancha' }
      },

      {
        path: '/OOS',
        component: OosDc,
        meta: { selectedMenu: 'OosDc' }
      },
      {
        path: '/MDD',
        component: MDD,
        meta: { selectedMenu: 'MDD' }
      },

      {
        path: 'Dev/History',
        component: PianchaLishi,
        meta: { selectedMenu: 'PianchaLishi' }
      },
      {
        path: '/OOS/History',
        component: OOSLishi,
        meta: { selectedMenu: 'OOSLishi' }
      },
      {
        path: '/MDD/History',
        component: MDDLishi,
        meta: { selectedMenu: 'MDDLishi' }
      },
      {
        path: 'Dev/Surveydb',
        component: PianchaNeirong,
        meta: { selectedMenu: 'PianchaNeirong' }
      },
      {
        path: '/OOS/Surveydb',
        component: OOSNeirong,
        meta: { selectedMenu: 'OOSNeirong' }
      },
      {
        path: '/MDD/Surveydb',
        component: MDDNeirong,
        meta: { selectedMenu: 'MDDNeirong' }
      },
      {
        path: '/ContactUs',
        component: ContactUs,
        meta: { selectedMenu: 'ContactUs' }
      },
      {
        path: '/Help',
        component: Help,
        meta: { selectedMenu: 'Help' }
      },
      
      
      {
        path: '/Count',
        component: Count,
        meta: { selectedMenu: 'Count' }
      },
      {
        path: '/Chat/:name',
        name: 'Chat',
        component: Chat,
        meta: { selectedMenu: 'Chat' }
      }
      ,
      {
        path: '/Application',
        component: Application,
        meta: { selectedMenu: 'Application' }
      }
      ,
      {
        path: '/Change',
        component: Change,
        meta: { selectedMenu: 'Change' }
      },
      {
        path: '/Knowledge',
        component: Knowledge,
        meta: { selectedMenu: 'Knowledge' }
      }
      ,
      {
        path: '/Market',
        component: Market,
        meta: { selectedMenu: 'Market' }
      },
      {
        path: '/Feedback',
        component: Feedback,
        meta: { selectedMenu: 'Feedback' }
      },
      {
        path: '/Setting',
        component: Setting,
        meta: { selectedMenu: 'Setting' }
      },
      // {
      //   path: '/Help',
      //   component: Help,
      //   meta: { selectedMenu: 'Help' }
      // },
      {
        path: '/PromptGogogogogo',
        component: Prompt,
        meta: { selectedMenu: 'Prompt' }
      },

    ]
  },
  {
    path: '/Login',
    component: Login
  },
  {
    path: '/LoginWx',
    component: LoginWx
  },



];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const whiteList = ['/Login', '/Market'];
//处理左侧导航栏;

let routesList = [
  {
    "path": "/home",
    "name": "home",
    "component": "home",
    "meta": {
      "title": "应用",
      "isLink": "",
      "isHide": false,
      "isKeepAlive": true,
      "isAffix": true,
      "isIframe": false,
      "icon": "el-icon-s-home"
    }
  },
  {
    "path": "/Translation",
    "name": "Translation",
    "component": "Translation",
    "meta": {
      "title": "翻译助手",
      "isLink": "",
      "isHide": false,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-c-scale-to-original"
    },
    "children": [
      {
        "path": "Translation/History",
        "name": "FangyiLishi",
        "component": "FangyiLishi",
        "meta": {
          "title": "翻译历史",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
    ]
  },

  {
    "path": "/CC",
    "name": "BiangengGuanli",
    "component": "BiangengGuanli",
    "meta": {
      "title": "变更风险评估",
      "isLink": "",
      "isHide": true,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-menu"
    },
    "children": [
      {
        "path": "/CC/History",
        "name": "BiangengLishi",
        "component": "BiangengLishi",
        "meta": {
          "title": "历史数据",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
      {
        "path": "/CC/Riskdb",
        "name": "BiangengNeirong",
        "component": "BiangengNeirong",
        "meta": {
          "title": "变更风险库",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
    ]
  },
  {
    "path": "/Dev",
    "name": "Piancha",
    "component": "Piancha",
    "meta": {
      "title": "偏差调查指引",
      "isLink": "",
      "isHide": true,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-menu"
    },
    "children": [
      {
        "path": "Dev/History",
        "name": "PianchaLishi",
        "component": "PianchaLishi",
        "meta": {
          "title": "历史数据",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
      {
        "path": "Dev/Surveydb",
        "name": "PianchaNeirong",
        "component": "PianchaNeirong",
        "meta": {
          "title": "调查库",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
    ]
  },
  {
    "path": "/OOS",
    "name": "OosDc",
    "component": "OosDc",
    "meta": {
      "title": "OOS调查指引",
      "isLink": "",
      "isHide": true,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-menu"
    },
    "children": [
      {
        "path": "/OOS/History",
        "name": "OOSLishi",
        "component": "OOSLishi",
        "meta": {
          "title": "历史数据",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
      {
        "path": "/OOS/Surveydb",
        "name": "OOSNeirong",
        "component": "OOSNeirong",
        "meta": {
          "title": "调查库",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
    ]
  },
  {
    "path": "/MDD",
    "name": "MDD",
    "component": "MDD",
    "meta": {
      "title": "MDD调查指引",
      "isLink": "",
      "isHide": true,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-menu"
    },
    "children": [
      {
        "path": "/MDD/History",
        "name": "MDDLishi",
        "component": "MDDLishi",
        "meta": {
          "title": "历史数据",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
      {
        "path": "/MDD/Surveydb",
        "name": "MDDNeirong",
        "component": "MDDNeirong",
        "meta": {
          "title": "调查库",
          "isLink": "",
          "isHide": true,
          "isKeepAlive": true,
          "isAffix": false,
          "isIframe": false,
          "roles": ["admin", "common"],
          "icon": "el-icon-c-scale-to-original"
        }
      },
    ]
  },
  {
    "path": "/account",
    "name": "zhanghu",
    "component": "Zhanghu",
    "meta": {
      "title": "账户",
      "isLink": "",
      "isHide": false,
      "isKeepAlive": true,
      "isAffix": false,
      "isIframe": false,
      "roles": ["admin", "common"],
      "icon": "el-icon-setting"
    },

  }

]

store.dispatch('routesList/setRoutesList', routesList);
store.dispatch('tagsViewRoutes/setTagsViewRoutes', routesList);
// router.beforeEach((to, from, next) => {
//     if (whiteList.indexOf(to.path) !== -1) {
//       // 在免登录白名单，直接进入
//       next()
//     } else {
//       const token = localStorage.getItem('token');
//       // 将token添加到请求头中
//       if (token !== undefined) {
//         next()
//       } else {
//         next(`/Login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
//       }
//     }
// })
//
// router.afterEach(() => {
//   NProgress.done()
// })
export default router
