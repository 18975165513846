<template>
  <div>
    <lishi :kuType="'3'"></lishi>
  </div>
</template>

<script>
import lishi from "../components/lishi.vue";
export default {
  name: "",
  components: { lishi },
};
</script>