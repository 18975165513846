<template>
  <div class="fanyilishi-box p-20">
    <p class="obj-title">
      <span>历史数据</span>
      <el-button type="primary" plain @click="back()">返回</el-button>
    </p>
    <div class="table-box">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ backgroundColor: '#F4F4F5', color: '#222' }"
      >
        <el-table-column type="index" label="编号" width="80"></el-table-column>
        <el-table-column prop="content" label="变更原因" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="detail(scope.row)" style="cursor: pointer">
              {{ scope.row.content }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="param.pageSize"
        layout=" prev, pager, next, sizes,jumper"
        :total="total"
        class="custom-pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="历史数据详情"
      :visible.sync="dialogVisible"
      width="60%"
      @close="handleClose"
    >
      <div class="pop-box f-c-b">
        <div class="pop-form">
          <div>
            <p class="jieguo-title">输入区域</p>
            <el-input
              readonly
              type="textarea"
              :rows="23"
              placeholder=""
              v-model="detailData.content"
              style="width: 100%; line-height: 30px"
              class="no-resize"
            >
            </el-input>
          </div>
        </div>
        <div class="jieguo-box">
           <p class="jieguo-title b-b-1">结果展示</p>


          <el-button
            type="primary"
            size="medium"
            class="biangeng-btn"
            @click="addTo()"
            >添加到调查库</el-button
          >

          <div class="table-box1">
            <el-table
              :data="tableData1"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              max-height="440"
              class="transparent-table"
            >
              <el-table-column type="selection" width="44"> </el-table-column>
              <el-table-column prop="date" label="全选" show-overflow-tooltip>
                <template #default="{ row }">
                  <span style="padding-right: 10px">
                    {{ row.value }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div class="page-box">
              <el-pagination
                background
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="page1"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize1"
                layout=" prev, pager, next, sizes"
                :total="total1"
                small
                :pager-count="4"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getInvestLog, addContent } from "@/api/order";

export default {
  name: "",
  components: {},
  props: {
    kuType: {
      type: String, // 类型校验
      required: true, // 是否必传
      default: '', // 默认值
    },
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      param: {
        page: 1,
        pageSize: 10,
        type: "",
      },
      tableData: [],
      multipleSelection: [],

      total1: 0,
      page1: 1,
      pageSize1: 8,
      tableData1: [],
      detailData: {},
      detailData1: [],
backPath:""
    };
  },

  created() {
    if (this.kuType == "1") {
     
      this.backPath = "/Dev";
    } else if (this.kuType == "2") {
     
      this.backPath = "/OOS";
    } else if (this.kuType == "3") {
     
      this.backPath = "/MDD";
    }

  },

  mounted() {
    this.getLishiData();
  },

  methods: {
    addTo() {
      this.multipleSelection.forEach((element) => {
        addContent({ content: element.value, type: this.kuType }).then((res) => {
          if (res.MobileHead.Code == 1) {
            this.$message.success(res.MobileHead.Message);
          } else {
            this.$message.error(res.MobileHead.Message);
          }
        });
      });
    },
    handleClose() {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    back() {
      this.$router.push({ path: this.backPath });
    },
    getLishiData() {
      this.param.type = this.kuType;

      getInvestLog(this.param).then((res) => {
        console.log(res);
        this.tableData = res.MobileBody.logList;
        this.total = res.MobileBody.totalCount;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.param.pageSize = val;
      this.param.page = 1;
      this.getLishiData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.param.page = val;
      this.getLishiData();
    },

    detail(data) {
      this.detailData = data;
      this.detailData1 = data.results;

      this.total1 = data.results.length;
      this.paginatedData();
      this.dialogVisible = true;
    },
    paginatedData() {
      const start = (this.page1 - 1) * this.pageSize1;
      const end = start + this.pageSize1;
      this.tableData1 = this.detailData1.slice(start, end);
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.page1 = 1; // 重置到第一页
      this.paginatedData();
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.paginatedData();
    },
  },
};
</script>

<style lang="scss" scoped>
.fanyilishi-box {
  .table-box {
    margin-top: 20px;
    border-radius: 8px;

    overflow: hidden; /* 隐藏超出部分 */
    border: 1px solid #ddd;

    .el-table {
      border-radius: 8px; /* 表格圆角 */
    }

    .el-table__header-wrapper,
    .el-table__body-wrapper {
      border-radius: 8px; /* 表头和表体圆角 */
    }
  }
  .pop-box {
   
    .pop-form {
      width: 50%;
      padding-right: 20px;
      box-sizing: border-box;
      p {
        margin-top: 20px;
        
      }
      .pop-flex {
        display: flex;
        justify-content: space-between;

        .pop-flex-item {
          width: calc(50% - 10px);
        }
      }
   
    }
    .jieguo-box {
      position: relative;
      width: 50%;
      background-color: #f4f4f5;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
      overflow: hidden;
      .biangeng-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99;
      }
    }
  }
  .text-cell {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制显示两行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5; /* 调整行高 */
  }

  :deep(.el-dialog__wrapper .el-dialog .el-dialog__body){
    padding-top: 0 !important;
    padding-right:  0 !important;
  }
}
</style>
<style>
.el-dialog__footer {
  text-align: center !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #222;
  color: #fff;
}
</style>
